<template>
    <div class="wrap">
        <archives :archivesData="archivesData"></archives>
    </div>
    
</template>

<script>
import archives from '@/components/archives/detail.vue'
export default {
    data(){
        return {
            archivesData:{}
        }
    },
    created(){
        this.getLatestDoc()
    },
    components:{
        archives
    },
    methods:{
        getLatestDoc() {
            this.$axios({
                url: 'healthy/queryLatestPatientDoc',
                data:{},
                elseData: {
                loading: true,
                },
            }).then((res) => {
                console.log(res)
                this.archivesData = res.d
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    overflow: auto;
}
</style>